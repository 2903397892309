.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.RaLayout-content-4 {
  padding-bottom: 0 !important;
}

.App-link {
  color: #61dafb;
}

/* Filters styles */
form.filters {
  margin-left: 4%;
  margin-top: 0;
  min-height: 36px;
  background-color: transparent !important;
}

form.filters + span + .MuiToolbar-root {
  min-height: 36px;
  margin-top: 0;
  padding-top: 0;
}

form.filters + span + .MuiToolbar-root .MuiButtonBase-root {
  height: 37px;
}

form.filters label.MuiInputLabel-filled.MuiInputLabel-marginDense {
  transform: none;
  position: absolute;
  top: -10px;
  left: 0;
  padding: 1px 8px 1px 20px;
  border-radius: 7px;
  background-color: #fc153b;
  color: white;
  font-size: 13px;
  line-height: 1.7;
  height: 21px;
}

form.filters .filter-field > button.MuiButtonBase-root {
  margin-left: 0;
  padding: 0;
  position: absolute;
  top: -5px;
  left: 6px;
  z-index: 9;
  color: white;
  transition: none;
  width: 10px;
  height: 10px;
}

@-moz-document url-prefix() {
  form.filters label.MuiInputLabel-filled.MuiInputLabel-marginDense {
    line-height: 19px;
  }
  form.filters .filter-field > button.MuiButtonBase-root {
    top: -4px;
  }
  form.filters .filter-field[data-source="status"] > button.MuiButtonBase-root {
    top: -7px;
  }
}

form.filters .filter-field .MuiInputAdornment-root {
  margin: auto 0;
  padding: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 24px;
  z-index: 9;
}

form.filters .filter-field > button.MuiButtonBase-root .MuiSvgIcon-root {
  display: none;
}

form.filters
  .filter-field
  > button.MuiButtonBase-root
  span.MuiIconButton-label {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
}

form.filters
  .filter-field
  > button.MuiButtonBase-root
  span.MuiIconButton-label:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  background: url("./assets/vectors/delete_white.svg") no-repeat center;
  background-size: 100%;
}

form.filters[class*="makeStyles-geofence"]
  .filter-field[data-source="active"]
  > button.MuiButtonBase-root
  span.MuiIconButton-label:before {
  background: url("./assets/vectors/delete.svg") no-repeat center;
}

form.filters .filter-field {
  position: relative;
  max-height: 37px;
}

form.filters .MuiFormHelperText-root {
  display: none;
}

form.filters .MuiFormControl-marginDense {
  padding-bottom: 0;
  margin-top: 0;
  margin-bottom: 0;
}

form.filters .MuiFilledInput-root.Mui-focused,
form.filters .MuiFilledInput-root:hover {
  background-color: transparent;
}

form.filters
  .MuiInputBase-input.MuiFilledInput-inputMarginDense.MuiFilledInput-input {
  padding-top: 15px;
  padding-bottom: 10px;
  padding-right: 35px;
  height: 10px;
  background: #f7f8fa;
  border-radius: 9px;
  border: solid 1.5px #fc153b;
  color: #001737;
  font-size: 14px;
  line-height: 18px;
  background-color: rgba(227, 227, 227, 0.36);
}

form.filters label.MuiInputLabel-filled {
  color: #fc153b;
}

form.filters .selectInput .MuiInputBase-root {
  padding-bottom: 0;
}

form.filters .selectInput .MuiInputBase-input.MuiFilledInput-inputMarginDense {
  padding-top: 11px;
  padding-bottom: 7px;
}

form.filters [class^="RaResettableTextField-clearButton"],
form.filters [class^="RaAutocompleteInput-clearButton"] {
  background: url("./assets/vectors/search.svg") no-repeat center;
}

form.filters .MuiFilledInput-underline:before,
form.filters .MuiFilledInput-underline:after {
  border-bottom: 0;
}

.label.MuiFormLabel-root {
  font-size: 0.75rem;
  padding-left: 12px;
}

.pointer {
  cursor: pointer;
}

.relative {
  position: relative;
}

.btn-without-hover:hover {
  background-color: transparent !important;
}

.btn-without-hover:after {
  display: none !important;
}

.icon-expand {
  margin-right: 10px;
  margin-left: 10px;
}

.right-wrapper {
  width: calc(100vw - 301px);
}

.label {
  font-size: 0.75rem;
  color: rgba(0, 0, 0, 0.38);
}

.margin-bottom-25 {
  margin-bottom: 25px !important;
}

.half-width {
  width: 50% !important;
}

.full-width {
  width: 100% !important;
  flex: 100% !important;
  margin: 0 !important;
}

.item {
  width: 100%;
}

.flex-container {
  display: flex;
  flex-wrap: wrap;
  width: 96% !important;
  background: #fff;
  padding: 25px 20px;
  border-radius: 12px;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 8%);
}
.flex-container:not(.column) > div {
  flex: calc(50% - 20px);
  margin-top: 0;
}
.flex-container:not(.column) > div:nth-child(2n + 1) {
  margin-right: 10px;
}
.flex-container:not(.column) > div:nth-child(2n) {
  margin-left: 10px;
}
.marginTop-80 {
  margin-top: 80px;
}

.toolbar {
  position: fixed;
  top: 115px;
  background: white;
  margin-left: 16px;
  z-index: 999;
  border-radius: 12px;
  box-shadow: rgb(0 0 0 / 8%) 0px 2px 5px 0px;
}

.flex-basis-48-percent {
  flex-basis: 48% !important;
  width: 48% !important;
}

.padding-left-zero {
  padding-left: 0 !important;
}

.create-btn span {
  padding-left: 0;
}

.main-container {
  margin-top: 60px;
  padding: 10px 20px 30px;
  background: white;
  margin-bottom: 12px;
  width: 96% !important;
  border-radius: 12px;
  box-shadow: rgb(0 0 0 / 8%) 0px 2px 5px 0px;
}

.flex-container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

.flex-container.column {
  flex-flow: column nowrap;
}

.hide {
  display: none !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
