.step {
  position: relative;
  flex: 1;
  font-size: 0.75rem;
  text-align: center;
}

.step:before {
  content: "";
  position: absolute;
  bottom: -1.688rem;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 1.125rem;
  height: 1.125rem;
  background: #fff;
  border: 0.165rem solid #ccc;
  border-radius: 50%;
  z-index: 2;
}
.step:after {
  content: "";
  position: absolute;
  bottom: -1.1rem;
  left: -50%;
  height: 0.23rem;
  width: 100%;
  background: #ccc;
}
.step:first-child:after {
  content: none;
}
.active:before {
  border-color: #fc153b;
}
.active:after {
  background: #fc153b;
}
