.listField {
  color: #354052;
  font-weight: 600;
}
.createButtonAction {
  text-transform: none !important;
  color: white !important;
  margin-right: 17px !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
  text-transform: capitalize;
}

.list-container {
  overflow-y: scroll;
  height: calc(100vh - 276px);
}

.datagrid {
  position: absolute;
  width: 100%;
}

.textInputs {
  width: 400px;
}

.editContainer {
  margin-top: 20;
  display: flex;
  /* justify-content: center;
    align-items: center; */
  /* height: 90vh; */
}

.edit {
  /* background: white; */
  width: 100%;
  /* box-shadow: rgb(0 0 0 / 8%) 0px 2px 5px 0px;
    border-radius: 12px; */
}

.topTools {
  margin-top: 7px;
}
