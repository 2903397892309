.container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row-reverse;
}
.left {
  flex-basis: 62%;
  width: 62%;
  padding-left: 10px;
  border-left: 1px solid rgba(226, 232, 240);
  box-sizing: border-box;
}
.right {
  flex-basis: 38%;
  width: 38%;
  padding-right: 1.25rem;
  box-sizing: border-box;
}
.stepper {
  height: 3px;
  background: #fc153b;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.938rem 0;
  width: calc(100% - 8px);
}
.stepper:before,
.stepper:after {
  content: "";
  width: 1.125rem;
  height: 1.125rem;
  border-radius: 50%;
  background: #fc153b;
}

@media screen and (max-width: 1200px) {
  .left {
    flex-basis: 100%;
    width: 100%;
    padding-left: 0;
    border-left: none;
  }
  .right {
    flex-basis: 100%;
    width: 100%;
    padding-right: 0;
  }
}
