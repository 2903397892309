html {
  font-size: 16px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.row-reverse {
  flex-direction: row-reverse;
}

.direction-column {
  flex-direction: column;
}

.justify-between {
  justify-content: space-between;
}

.margin-b-15 {
  margin-bottom: 1rem;
}

.margin-b-10 {
  margin-bottom: 0.625rem;
}
.margin-t-10 {
  margin-top: 0.625rem;
}
.margin-r-10 {
  margin-right: 0.625rem;
}

.red {
  color: #fc153b !important;
}

.card {
  margin: 0.25rem;
  padding: 1.6rem;
  background: #fff;
  border-radius: 6px;
  box-shadow: 0px 3px 26px rgb(0 0 0 / 16%);
}

.background-red {
  background-color: #fc153b !important;
}
.background-red:disabled {
  background-color: rgba(0, 0, 0, 0.12) !important;
}

.text-center {
  text-align: center;
}

.align-baseline {
  align-items: baseline;
}

.font-size-16 {
  font-size: 1rem;
}

.font-size-24 {
  font-size: 1.5rem;
}

.font-bold {
  font-weight: 700;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media screen and (max-width: 1024px) {
  html {
    font-size: 14px;
  }
}

@media screen and (max-width: 768px) {
  html {
    font-size: 12px;
  }
}
