.error-overlay-container {
  position: fixed;
  z-index: 1000;
  bottom: 0;
  right: 0;
  height: 0;
  overflow: visible;
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: nowrap;
  align-items: flex-end;
}
.error-overlay-list-bubble {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 100px);
  max-height: calc(100vh - 100px);
  flex: 0 0 288px;
  width: 288px;
  min-width: 0;
  background: white;
  margin-left: 16px;
  margin-right: 16px;
  box-shadow: 0 0 0 1px rgb(0 0 0 / 10%), 0 4px 6px rgb(0 0 0 / 20%);
  transition-duration: 167ms;
  animation: fade-in 167ms cubic-bezier(0.4, 0, 1, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  border-radius: 8px 8px 0 0;
}

.error-overlay-list-bubble--is-minimized {
  height: 48px;
}
.error-overlay-bubble-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 8px;
  min-height: 48px;
  position: relative;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 2px 2px 0 0;
  cursor: pointer;
  background-clip: padding-box;
  background-color: #dc3545;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.error-overlay-bubble-header__details {
  display: flex;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 4px;
  padding: 4px 4px 4px 1px;
  position: relative;
  flex-direction: row;
  align-items: center;
}
.error-overlay-bubble-header__controls {
  font-weight: 400;
  color: white;
  transition-duration: 167ms;
  margin-left: auto;
  flex-shrink: 0;
  opacity: 1;
  position: relative;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  display: flex;
}
.error-img-ctn {
  width: 32px;
  height: 32px;
  display: flex;
  position: relative;
}
.error-img-ctn img {
  cursor: pointer;
  background-size: cover;
}
.error-overlay-bubble-header__button {
  max-width: 230px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 8px;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}
.error-overlay-bubble-header__button h4 {
  margin: 0;
  font-size: 16px;
  line-height: 1.42857;
  font-weight: 600;
  color: white;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.error-overlay-list-bubble-search {
  display: flex;
  flex-direction: column;
}
.error-overlay-list-bubble-search__input-container {
  position: relative;
  background: 0 0;
}
.error-overlay-list-search__search-icon {
  position: absolute;
  top: 10px;
  left: 20px;
  font-size: 1.2rem;
  color: #6c6c6c;
}

.error-overlay-list-bubble-search__search-typeahead-input {
  background-color: rgb(238, 243, 248);
  box-shadow: none;
  font-size: 16px;
  margin: 8px 12px;
  padding: 0 28px 2px 32px;
  border-radius: 4px;
  border-width: 0;
  border: none;
  width: 201px;
}
.error-overlay-list-bubble-search__search-typeahead-input::placeholder {
  font-size: 12px;
}
.error-overlay-list-bubble-search__search-typeahead-input:focus {
  outline: none;
}
.error-overlay-list-filter-icon {
  position: absolute;
  top: 16px;
  right: 24px;
  font-size: 12px;
  color: #6c6c6c;
  cursor: pointer;
}
.scroll-panel-container {
  height: calc(100vh - 100px);
  overflow-y: auto;
  overflow-x: hidden;
}
.error-overlay-list-item {
  height: 66px;
  border: 1px 0 1px 0 solid rgb(233, 229, 223);
}

.error-overlay-list-item:hover {
  background: rgba(0, 0, 0, 0.08);
}

.error-overlay-list-card {
  width: 100%;
  padding-left: 8px;
  height: inherit;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 83ms;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  left: 0;
  display: flex;
}
.error-selectable-div {
  min-width: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.error-icon-grid {
  width: 48px;
  height: 48px;
  transition: visibility 0.1s ease-in-out;
  position: absolute;
  display: table-cell;
  vertical-align: middle;
}

.error-icon-grid img {
  width: 30px;
  height: 30px;
  box-sizing: border-box;
  background-clip: content-box;
  border: none;
}

.error-overlay-list-bubble__card-content {
  height: auto;
  padding: 8px 12px 8px 8px;
  display: flex;
  flex-grow: 1;
  vertical-align: top;
  border-bottom: 1px solide #e9e5df;
  justify-content: space-between;
  overflow: hidden;
  padding-left: 8px;
}

.error-overlay-list-bubble__convo-card-content-wrapper {
  width: 100%;
  flex-grow: 2;
  float: left;
}
.error-card__row {
  display: flex;
}
.listitem__error-names {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 400;
  font-style: normal;
  display: block;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  color: rgba(0, 0, 0, 0.9);
  outline-color: rgba(0, 0, 0, 0.9);
}
.listitem__error-message {
  font-size: 14px;
  color: grey;
}
