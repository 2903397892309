#import-card {
  height: "50vh";
}
#import-card::before {
  content: "";
  background-image: url("./IT-support-new-illustration.svg");
  background-size: 30%;
  background-repeat: no-repeat;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0.7;
  background-position-x: right;
  background-color: transparent;
  background-position-y: center;
}
#import-card {
  z-index: 10;
  position: relative;
}
