.category-selector-container {
  position: relative;
  z-index: 999;
  min-width: 200px;
}

.category-selector__control {
  /* border-radius: 9px !important; */
  /* border: solid 1.5px #f5010b !important; */
  background: #e3e3e3 !important;
  box-shadow: none !important;
}

.category-selector__value-container {
  text-transform: capitalize;
}

.category-selector__indicator-separator {
  display: none !important;
}

/* .category-selector__indicator svg {
  fill: var(--primary-color);
} */

.category-selector__menu {
  width: 120% !important;
  left: -8px;
  border-radius: 12px !important;
  margin-top: 0 !important;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.15) !important;
}

.category-selector__menu::before {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 11.5px 15px 11.5px;
  border-color: transparent transparent #ffffff transparent;
  position: absolute;
  top: -6px;
  left: 5px;
}

.category-selector-list {
  padding: 5px 10px;
}

.category-selector__option {
  background: #fff !important;
  color: inherit !important;
  text-transform: capitalize;
}

.category-selector__option--is-focused,
.category-selector__option--is-selected {
  background: #f9f9f9 !important;
}
.category-selector__option--is-focused {
  color: inherit !important;
}

.category-selector__option--is-selected {
  color: var(--primary-color) !important;
}
